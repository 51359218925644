import React from "react";
import profile from "../profile.png";
import "../App.scss";

const Banner = () => (
  <div>
    <div className="top-section-profle">
      <img src={profile} className="img mr-16" alt="Logo of Marc Stauffer" />
      <div>
        Marc Stauffer
        <div className="subdued">I value practical software</div>
        <div className="subdued">built by teams who care.</div>
      </div>
    </div>
  </div>
);

export default Banner;
