import React from "react";
import "../App.scss";
import PropTypes from "prop-types";

const EducationItem = ({ time, degree, institution }) => (
  <div className="section pt-24">
    <div className="section-header">
      <div className="pr-4">{time}</div>
      <div><a
        className="accent"
        href="https://www.hdm-stuttgart.de/en"
        target="_blank"
        rel="noopener noreferrer"
      >
        {institution}
      </a></div>
    </div>
    <div className="accent-2">{degree}</div>
  </div>
);

EducationItem.propTypes = {
  time: PropTypes.string.isRequired,
  degree: PropTypes.string.isRequired,
  institution: PropTypes.string.isRequired,
};

const Education = () => (
  <div className="section">
    <EducationItem
      time="2015-2017"
      degree="MSc. Computer Science and Media"
      institution="Stuttgart Media University"
    />
    <EducationItem
      time="2012-2015"
      degree="BSc. Mobile Media"
      institution="Stuttgart Media University"
    />
  </div>
);

export default Education;
