import React from "react";
import PropTypes from "prop-types";
import "../App.scss";

const WorkExperienceItem = ({
  time,
  title,
  company,
  companyUrl,
  description,
}) => (
  <div className="section pt-24">
    <div className="section-header">
      <span className="pr-4">{time}</span>
      <div>
        <a
          className="accent"
          href={companyUrl}
          target="_blank"
          rel="noreferrer"
        >
          {company}
        </a>
      </div>
    </div>
    <div className="description">
      <div className="accent-2">{title}</div>
      <div className="subdued">{description}</div>
    </div>
  </div>
);

WorkExperienceItem.propTypes = {
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  companyUrl: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
};

const WorkingHours = () => (
  <div className="section">
    <WorkExperienceItem
      time="2021-20??"
      title="Principal Product Experience Designer"
      company="Dynatrace"
      companyUrl="https://www.dynatrace.com/"
      description={
        <>
          Currently leading design for{" "}
          <a
            className="subdued"
            href="https://www.dynatrace.com/hub/detail/dashboards/?query=dash&filter=all"
            target="_blank"
            rel="noreferrer"
          >
            Dashboards
          </a>
          ,{" "}
          <a
            className="subdued"
            href="https://www.dynatrace.com/hub/detail/notebooks/?query=notebooks&filter=all"
            target="_blank"
            rel="noreferrer"
          >
            Notebooks
          </a>
          ,{" "}
          <a
            className="subdued"
            href="https://www.dynatrace.com/news/blog/davis-copilot-expands-get-answers-and-insights-across-the-dynatrace-platform/"
            target="_blank"
            rel="noreferrer"
          >
            CoPilot Chat
          </a>{" "}
          and platform UI.
        </>
      }
    />
    <WorkExperienceItem
      time="2015-2021"
      title="Senior UX Designer"
      company="Capgemini"
      companyUrl="https://www.capgemini.com/"
      description="Built and scaled an innovation lab and its UX team. Main customer: Mercedes-Benz."
    />
  </div>
);

export default WorkingHours;
