import React from "react";

const SocialLinks = () => (
  <div className="pt-24">
    <a
      target="_blank"
      rel="noreferrer"
      className="mr-8 subdued"
      href="https://www.linkedin.com/in/marc-stauffer/"
    >
      LinkedIn
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      className="subdued"
      href="mailto:mail@marcstauffer.xyz"
    >
      Email
    </a>
  </div>
);

export default SocialLinks;
